/* Same as before */
body {
  background-color: #f7fafc;
  font-family: Arial, sans-serif;
}
.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 32px;
  background-color: #ffffff;
  font-size: 0.8rem;
}
.title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
  font-family: Arial, sans-serif;
}
.subtitle {
  font-size: 1.05rem;
  font-weight: bold;
  text-align: center;
  font-family: Arial, sans-serif;
}
.formGroup {
  margin-bottom: 20px;
}
.formGroup label {
  display: block;
  font-weight: 600;
  margin-bottom: 4px;
}
.formGroup input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}
.formGroup input[disabled] {
  background-color: #e5e7eb;
}
.table {
  width: 100%;
  margin-bottom: 8px;
  border-collapse: collapse;
}
.th,
.td {
  padding: 12px;
  border: 1px solid #d1d5db;
}
th {
  background-color: #edf2f7;
}
.sectionTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 25px;
}
.textCenter {
  text-align: center;
}
.grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}
.grid div {
  width: 48%;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}
.header .logo {
  max-width: 100px;
  max-height: auto;
}
.header .info {
  flex: 1;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.answerColumn {
  width: 150px;
  text-align: center;
  padding: 12px;
  border: 1px solid #d1d5db;
}
.signature {
  width: 200px;
  height: 100px;
  margin: 0 auto;
}
.footer {
  display: none;
}
@media print {
  .page-break {
    page-break-before: always;
  }
  @page {
    size: A4;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .container {
    height: 100%;
  }
}
